import React, { useState, useEffect } from "react";
import Form from "../src/components/Form";
import logo from "../src/assets/images/logos/pixeloopLogo.svg";
import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { login, selectisauth } from "../src/Redux/user/UserSlice";
import Link from "next/link";
import { showAlert, hideAlert } from "../src/Redux/alert/alertSlice";
import ShowMgsInfo from "../src/components/showMsgInfo";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import googleIcon from "../src/assets/images/icons/flat-color-icons_google.png";
import { verifyRecaptcha } from "../src/Redux/recapcha/recaptchaSlice";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import LanguageSwitcher from "../src/components/language/LanguageSwitcher";

const Login = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const SITE_KEY = process.env.NEXT_PUBLIC_SITE_KEY;
  const [captchaToken, setCaptchaToken] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useDispatch();
  const { redirect } = router.query;
  const isauth = useSelector(selectisauth);

  const fields = [
    {
      name: "email",
      label: "",
      type: "email",
      defaultValue: ""||formValues?.email,

      placeholder: t('login.form.fields.email.placeholder'),
      group: 1
    },
    { 
      name: "password", 
      label: "", 
      type: "password", 
      defaultValue: ""||formValues?.password,

      placeholder: t('login.form.fields.password.placeholder'), 
      group: 2 
    },
  ];

  const validationSchema = { email: { required: true }, password: { required: true } };
  const { verified, loading, error } = useSelector((state) => state.recaptcha);

  useEffect(() => {
    if (error) {
      dispatch(
        showAlert({
          message: error,
          color: "danger",
          timeout: 5000,
        })
      );
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (redirect && !isauth && router.pathname.includes("login")) {
      dispatch(
        showAlert({
          message: t('login.alerts.loginRequired'),
          color: "warning",
          timeout: 5000,
        })
      );
    }
  }, [redirect, isauth, dispatch, router, t]);

  const handleSubmit = async (values) => {
    setFormValues(values);

    if (SITE_KEY && executeRecaptcha) {
      try {
        const token = await executeRecaptcha("loginSubmit");
        const recaptchaResponse = await dispatch(verifyRecaptcha(token)).unwrap();

        if (recaptchaResponse.success) {
          setCaptchaToken(token); // Mise à jour avec la bonne fonction
          handleFinalSubmit(values);
        } 
        else {
          dispatch(
            showAlert({
              message: t('login.alerts.recaptchaVerificationFailed'),
              color: "danger",
              timeout: 5000,
            })
          );
        }
      } catch (error) {
        console.error("Error during Recaptcha validation:", error);
        dispatch(
          showAlert({
            message: error.message || t('login.alerts.errorRecapcha'),
            color: "danger",
            timeout: 5000,
          })
        );
      }
    } else {
      handleFinalSubmit(values);
    }
  };

  const handleFinalSubmit = (values) => {
    const data = { email: values.email, password: values.password };
    dispatch(login(data)).then((response) => {
      if (response?.payload?.code === "2000") {
        localStorage.setItem(
          "fullname",
          `${response.payload.data.user.firstname} ${response.payload.data.user.lastname}`
        );
        localStorage.setItem("email", response.payload.data.user.email);
        dispatch(hideAlert());
        ; // Clear token after successful submission
        handleLogin();
      } else {
        dispatch(
          showAlert({
            message: response?.payload?.error || t('login.alerts.error'),
            color: "danger",
          })
        );
      }
    });
  };

  const handleLogin = () => {
    const token = new URLSearchParams(window.location.search).get("token");
    if (token) {
      localStorage.setItem("access_token", token);
      router.push("/");
    } else {
      router.push(redirect || "/");
    }
  };

  const handleGoogleLogin = () => {
    const authUrl = `${process.env.NEXT_PUBLIC_API_URL}/authentification?prompt=select_account`;
    router.push(authUrl);
  };

  return (
    <div className="login-container">
      <div className="logo-content-centred cursor-pointer">
        <Link href="/" passHref>
          <span>
            <Image src={logo} alt="logo image" width={120} height={40} />
          </span>
        </Link>
      </div>
      <h3 className="pe-1">{t('login.title')}</h3>
      <p className="text-gray">{t('login.description')}</p>
      <div className="login-forms">
        <ShowMgsInfo />
        <Form
          fields={fields}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          btnText={t('login.form.buttons.signin')}
          ActionText= {t('login.form.links.signupAction')}
          Description={t('login.form.links.signup')}
          href="/register"
          forgotPasswordtext={t('login.form.links.forgotPassword')}
          forgotPasswordhref="forgot-password"
        />
        <div className="hrr-container">
          <div className="hr-container">
            <hr className="google-custome-line" />
          </div>
          <span className="or-text">{t('login.or')}</span>
          <div className="hr-container">
            <hr className="google-custome-line" />
          </div>
        </div>
        <button onClick={handleGoogleLogin} className="google-btn">
          <div className="google-btn-container">
            <div className="google-icon">
              <Image src={googleIcon} alt="google-icon"  />
            </div>
            <div>
              <span className="google-text-btn">{t('login.form.buttons.google')}</span>
            </div>
          </div>
        </button>
      </div>
      <div className="margin-block-start-40">
      <LanguageSwitcher />
      </div>
      
    </div>
  );
};

const LoginPage = () => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_SITE_KEY}>
    <Login />
  </GoogleReCaptchaProvider>
);

LoginPage.layout = "login";

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale || 'en', ['common'])),
    },
  };
}

export default LoginPage;